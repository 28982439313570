<template>
	<div>
		<v-row style="padding:40px">
			<img :src="$store.state.icons.icons['favicon-32x32']" />
		</v-row>
		<v-row class="align-center justify-center" style="height:70vh">
			<v-col cols="12" sm="2" md="4"></v-col>
			<v-col cols="12" sm="8" md="4">
				<v-form v-model="valid" ref="form" lazy-validation>
					<v-card tag="div" elevation="0" outlined color="#F7F9FC">
						<p class="text-center my-heading">Log in</p>

						<v-row class="p-0">
							<v-col cols="12" class="pb-0">
								<label>Email address</label>
							</v-col>
							<v-col cols="12" class="pt-1">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="text"
									placeholder="Email address"
									v-model="loginUser.email"
									class="text-lowercase"
									:rules="emailRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="p-0">
							<v-col cols="12" class="pb-0">
								<label>Password</label>
							</v-col>
							<v-col cols="12" class="pt-1">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="password"
									placeholder="Password"
									v-model="loginUser.password"
									:rules="passwordRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="align-center justify-center p-0 custom-error" :style="{'display':ShowMessage}">
							<v-col cols="12">{{this.errorMessages}}</v-col>
						</v-row>

						<v-row class="p-0">
							<v-col cols="12">
								<v-btn
									class="my-p-btn my_elevation"
									id="my_elevation"
									depressed
									block
									@click="logIn"
									:disabled="!valid"
								>Log in</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-form>
			</v-col>
			<v-col cols="12" sm="2" md="4"></v-col>
		</v-row>
	</div>
</template>


<script>
import { AXIOS } from "../../plugins/axios";
import Cookies from "js-cookie";

export default {
	data() {
		return {
			loginUser: {
				email: "",
				password: "",
				role: "A"
			},
			show: true,
			valid: true,
			passwordRules: [
				v => !!v || "Password is required",
				v =>
					(v && v.length >= 8) ||
					"Password must be greater than 8 characters"
			],
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+/.test(v) || "E-mail must be valid"
			],
			errorMessages: "",
			showMessage: "none"
		};
	},
	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		},
		ShowMessage() {
			return (this.showMessage = !this.errorMessages ? "none" : "block");
		}
	},

	methods: {
		logIn() {
			Cookies.remove("admin_access_token");
			Cookies.remove("admin_refresh_token");
			Cookies.remove("admin_fcm_id");

			if (this.$refs.form.validate()) {
				AXIOS.post("admin/auth/login", this.loginUser)
					.then(resp => {
						this.$store.dispatch("authUser", resp.data.user);
						let access_token = resp.data.access_token;
						let refresh_token = resp.data.refresh_token;
						this.$store.dispatch("authRequest", { access_token });
						Cookies.set("admin_refresh_token", refresh_token, {
							expires: 90
						});
						AXIOS.defaults.headers.common[
							"X-Authorization"
						] = refresh_token;
						this.$router.push({ path: "/" });
					})
					.catch(error => {
						this.errorMessages = "";
						const response = error.response;
						if (response === undefined || response.status === 500) {
							this.$router.push({ path: "/500" });
						}
						this.errorMessages = response.data.message;
					});
			}
		}
	}
};
</script>
